import React, {Component} from "react";
import {connect, ConnectedProps} from "react-redux";
import {withTranslation, WithTranslation} from "react-i18next";
import Title from "../../../components/Title";
import {RootState} from "../../../redux";
import {changeGameState} from "../../../redux/global";
import {clearGameInstanceState} from "../../../redux/game1";
import '../frame.css';
import Scoreboard from "./Scoreboard";

const reduxConnector = connect(
    (state: RootState, ownProps: Partial<StartProps>) => ({
        gameState: state.global.gameState,
        game: state.global.game
    }),
    {changeGameState, clearGameInstanceState},
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        store: stateProps,
        dispatcher: dispatchProps,
    })
)

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type ConnectedStartProps = PropsFromRedux & StartProps;

export interface StartProps extends WithTranslation {
    onDone?: () => void,
}

export interface StartState {
    countdown: number,
}

const MAX_COUNTDOWN = 1;

class Start extends Component<ConnectedStartProps, StartState> {
    readonly name = "Start";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {}
    static defaultProps = {}

    private cancelCb: ReturnType<typeof setInterval> | null = null;

    constructor(props: ConnectedStartProps) {
        super(props);
        this.log("constructor", props);
        this.state = {
            countdown: MAX_COUNTDOWN,
        }
    }

    componentDidMount() {
        this.props.dispatcher.clearGameInstanceState(null);
        this.start();
    }

    start() {
        this.setState({countdown: MAX_COUNTDOWN});
        this.cancelCb = setInterval(() => {
            this.setState(state => ({countdown: Math.max(0, state.countdown - 1)}), () => {
                if (this.state.countdown <= 0) {
                    this.pause();
                    this.props.onDone?.();
                }
            });
        }, 1000);
    }

    pause() {
        this.cancelCb && clearInterval(this.cancelCb);
    }

    render() {
        return (
            <>
                <div className={"header"}>
                    <Title header={this.props.t("start.title")}/>
                </div>
                <div className={"frame"}>
                    <div className={"canvas"}>
                        <Title header={this.props.t("start.start_text")} color={'#0e4d3b'}/>
                    </div>
                </div>
                <div className={"footer"}>
                    <Scoreboard score_label={this.props.t("scoreboard.score_label")}
                                time_label={this.props.t("scoreboard.time_label")}
                                level_label={this.props.t("scoreboard.level_label")}
                                score={this.props.t("scoreboard.score_value", {score: 0})}
                                time={this.props.t("scoreboard.time_value", {time: 0})}
                                level={this.props.t("scoreboard.level_value", {level: 1})} />
                </div>
            </>
        );
    }
}

export default reduxConnector<any>(withTranslation()(Start));

import React, {Component} from "react";
import {Navigate} from "react-router-dom";
import {connect, ConnectedProps} from 'react-redux'
import {withTranslation, WithTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {RootState} from "../../../redux";
import {changeGameState, addGameResults} from "../../../redux/global";
import {clearGameInstanceState as clearGame1} from "../../../redux/game1";
import {clearGameInstanceState as clearGame2} from "../../../redux/game2";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import {GameState, GameType} from "../../../types";
import '../frame.css';

const reduxConnector = connect(
    (state: RootState, ownProps: Partial<EndGameProps>) => ({
        gameState: state.global.gameState,
        game: state.global.game,
        game1Data: state.game1.data,
        game2Data: state.game1.data,
    }),
    {changeGameState, addGameResults, clearGame1: clearGame1, clearGame2: clearGame2},
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        store: stateProps,
        dispatcher: dispatchProps,
    })
)

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type ConnectedEndGameProps = PropsFromRedux & EndGameProps;

export interface EndGameProps extends WithTranslation {

}

export interface EndGameState {
    to: string;
    score: number;
    block: number;
    time: number;
    attempts: number;
    speed: number;
    accuracy: number;
}

class EndGame extends Component<ConnectedEndGameProps, EndGameState> {
    readonly name = "EndGame";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {}
    static defaultProps = {}

    constructor(props: ConnectedEndGameProps) {
        super(props);
        this.log("constructor", props);
        let game = this.props.store.game;
        let gameData = game === GameType.MEMORY_GAME ? this.props.store.game1Data : game === GameType.NUMBER_GAME ? this.props.store.game2Data : {};
        let level = gameData.level;
        let score = gameData.score || 0;
        let block = score / 5;
        let time = (gameData.timer || 10) / 10;
        let attempts = (gameData.attempts || block)
        let speed = block / time;
        let accuracy = (block / attempts) || 0;
        this.props.dispatcher.addGameResults({
            game, level, score, block, time, attempts, speed, accuracy
        })
        switch (game) {
            case GameType.MEMORY_GAME:
                this.props.dispatcher.clearGame1(null);
                break;
            case GameType.NUMBER_GAME:
                this.props.dispatcher.clearGame2();
                break;
        }
        this.state = {
            to: "",
            score, block, time, attempts, speed, accuracy
        }
    }

    componentDidMount() {

    }

    restart() {
        this.props.dispatcher.changeGameState(GameState.COUNTDOWN);
    }

    menu() {
        this.setState({to: '/'});
    }

    render() {
        return (
            <>
                <div className={"header"}>
                    <Title header={this.props.t("endGame.title")}/>
                </div>
                <div className={"frame"}>
                    <div className={"canvas"}>
                        <table cellSpacing="0" cellPadding="0" style={{border: "none", tableLayout: "auto", width: '100%', margin: '3% 8%', fontSize: '2em'}}>
                            <colgroup>
                                <col style={{width: "2%"}}/>
                                <col style={{width: "2%"}}/>
                                <col style={{whiteSpace: "nowrap"}}/>

                            </colgroup>
                            <tr>
                                <th style={{textAlign: "right", whiteSpace: "nowrap"}}>{this.props.t("scoreboard.time_label")}</th>
                                <td style={{paddingLeft: "2%", paddingRight: "2%"}}>:</td>
                                <td style={{textAlign: "center"}}>{this.props.t("scoreboard.time_value", {time: this.state.time})}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "right", whiteSpace: "nowrap"}}>{this.props.t("scoreboard.score_label")}</th>
                                <td style={{paddingLeft: "2%", paddingRight: "2%"}}>:</td>
                                <td style={{textAlign: "center"}}>{this.props.t("scoreboard.score_value", {score: this.state.score})}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "right", whiteSpace: "nowrap"}}>{this.props.t("scoreboard.speed_label")}</th>
                                <td style={{paddingLeft: "2%", paddingRight: "2%"}}>:</td>
                                <td style={{textAlign: "center"}}>{this.props.t("scoreboard.speed_value", {speed: (this.state.speed).toFixed(2)})}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "right", whiteSpace: "nowrap"}}>{this.props.t("scoreboard.accuracy_label")}</th>
                                <td style={{paddingLeft: "2%", paddingRight: "2%"}}>:</td>
                                <td style={{textAlign: "center"}}>{this.props.t("scoreboard.accuracy_value", {accuracy: (this.state.accuracy * 100).toFixed(1)})}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className={"footer"}>
                    <Button onClick={this.restart.bind(this)}>{this.props.t("endGame.restart")}</Button>
                    <Button onClick={this.menu.bind(this)}>{this.props.t("endGame.menu")}</Button>
                </div>
                {this.state.to && <Navigate to={this.state.to} />}
            </>
        )
    }
}

export default reduxConnector<any>(withTranslation()(EndGame));

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import zh from "./zh.json";

export function i18nInit(lang: "en"|"zh" = "en") {
    i18n.use(initReactI18next).init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        compatibilityJSON: 'v3',
        resources: {
            en: {
                translation: en,
            },
            zh: {
                translation: zh
            }
        },
        lng: lang, // en | zh
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    }).then(r => {
        console.debug("[i18n] - finished init with languageCode", en)
    });
    return i18n;
}

export default i18n;

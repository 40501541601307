import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from 'react';
import {connect, Provider} from 'react-redux';
import store, {RootState} from "./redux";
import Main from "./routes/main";
import Game from "./routes/game"
import {GameType} from "./types";
import {i18nInit} from "./i18n";

interface AppProps {

}

interface AppState {

}

const ConnectedRoutes = connect(
    (state: RootState) => ({}),
    {},
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        store: stateProps,
        dispatcher: dispatchProps,
    })
)(()=>{
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/game1" element={<Game variant={GameType.MEMORY_GAME}/>} />
                <Route path="/game2" element={<Game variant={GameType.NUMBER_GAME}/>} />
            </Routes>
        </BrowserRouter>
    )
})

export default class App extends React.Component <AppProps, AppState> {
    readonly name = "CountDown";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {}
    static defaultProps = {}

    constructor(props: {}) {
        super(props);
        this.log("constructor", props);
        this.state = {};
        let browserLang = window.navigator.language;
        let browserLangNorm = browserLang.slice(0,2);
        i18nInit((browserLangNorm === "en" || browserLangNorm === "zh") ? browserLangNorm : "en");
    }

    render() {
        return (
            <Provider store={store}>
                <div className="App" style={{backgroundColor: '#006e5f'}}>
                    <ConnectedRoutes />
                </div>
            </Provider>
        );
    }
}

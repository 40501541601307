import React, {Component} from "react";
import PropTypes from "prop-types";
import { CSSTransition } from 'react-transition-group';
import "./AnimatedBox.css";
export interface AnimatedBoxProps extends React.PropsWithChildren {
    show: boolean,
}
export interface AnimatedBoxState {

}

export default class AnimatedBox extends Component<AnimatedBoxProps, AnimatedBoxState> {
    readonly name = "AnimatedBox";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {
        show: PropTypes.bool.isRequired,
    }
    static defaultProps = {}

    constructor(props: AnimatedBoxProps) {
        super(props);
        this.log("constructor", props);
    }

    render() {
        return (
            <CSSTransition in={this.props.show} appear={this.props.show} timeout={2000} classNames="animatedbox-fade">
                {this.props.children}
            </CSSTransition>
        );
    }
}

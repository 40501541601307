import {createSlice} from '@reduxjs/toolkit';
import {GameInstanceState} from "../containers/flow/game1/GameInstance";

export const game1Slice = createSlice({
    name: "game1",
    initialState: {
        data: {} as Partial<GameInstanceState>
    },
    reducers: {
        setGameInstanceState: (state, {payload}) => {
            console.log(payload);
            state.data = Object.assign(state.data, payload) as Partial<GameInstanceState>;
        },
        clearGameInstanceState: (state, {payload}) => {
            state.data = {} as Partial<GameInstanceState>;
        }
    }
});

export default  game1Slice.reducer;
export const {setGameInstanceState, clearGameInstanceState} = game1Slice.actions

import React, {Component} from "react";
import {connect, ConnectedProps} from 'react-redux'
import { withTranslation,WithTranslation } from 'react-i18next';
import {RootState} from "../../../redux";
import {initGame, changeGameState} from "../../../redux/global";
import {GameType, GameState} from "../../../types";
import Button from "../../../components/Button";
import Title from "../../../components/Title";
import '../frame.css';

const reduxConnector = connect(
    (state: RootState, ownProps: Partial<MenuProps>) => ({
        gameState: state.global.gameState,
        game: state.global.game
    }),
    {initGame, changeGameState},
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        store: stateProps,
        dispatcher: dispatchProps,
    })
)

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type ConnectedMenuProps = PropsFromRedux & MenuProps;

export interface MenuProps extends WithTranslation {

}

export interface MenuState {

}

class Menu extends Component<ConnectedMenuProps, MenuState> {
    readonly name = "Menu";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {}
    static defaultProps = {}

    constructor(props: ConnectedMenuProps) {
        super(props);
        this.log("constructor", props);
    }

    componentDidMount() {

    }

    gotoGame1() {
        this.props.dispatcher.initGame(GameType.MEMORY_GAME);
    }

    gotoGame2() {
        this.props.dispatcher.initGame(GameType.NUMBER_GAME);
    }

    exit() {
        this.props.dispatcher.changeGameState(GameState.EXIT);
    }

    render() {
        return (
            <>
                <div className={"header"}>
                    <Title header={this.name}/>
                </div>
                <div className={"frame"}>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: '70%'
                    }}>
                        <Button onClick={this.gotoGame1.bind(this)}>{this.props.t("games.0.name")}</Button>
                        <Button onClick={this.gotoGame2.bind(this)}>{this.props.t("games.1.name")}</Button>
                        <Button onClick={this.exit.bind(this)}>{this.props.t("menu.exit")}</Button>
                    </div>
                </div>
            </>
        )
    }
}

export default reduxConnector<any>(withTranslation()(Menu));

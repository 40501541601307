import {createSlice} from '@reduxjs/toolkit';
import {GameInstanceState} from "../containers/flow/game1/GameInstance";

export const game2Slice = createSlice({
    name: "game2",
    initialState: {
        data: {} as Partial<GameInstanceState>
    },
    reducers: {
        setGameInstanceState: (state, {payload}) => {
            console.log(payload);
            state.data = Object.assign(state.data, payload) as Partial<GameInstanceState>;
        },
        clearGameInstanceState: (state) => {
            state.data = {} as Partial<GameInstanceState>;
        }
    }
});

export default  game2Slice.reducer;
export const {setGameInstanceState, clearGameInstanceState} = game2Slice.actions

export enum GameState {
    MENU="MENU",
    TUTORIAL="TUTORIAL",
    COUNTDOWN="COUNTDOWN",
    START="START",
    INGAME="INGAME",
    PAUSE="PAUSE",
    ENDGAME="ENDGAME",
    EXIT="EXIT"
}

export enum GameType {
    UNDEFINED="UNDEFINED",
    NUMBER_GAME="NUMBER_GAME",
    MEMORY_GAME="MEMORY_GAME"
}

export interface GameResult {
    game: string,
    level: number,
    time: number,
    score: number,
    block: number,
    attempts: number,
    speed: number,
    accuracy: number,
}

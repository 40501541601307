import {configureStore} from '@reduxjs/toolkit';
import globalReducer from './global';
import game1Reducer from './game1';
import game2Reducer from './game2';

export const store = configureStore({
    reducer: {
        global: globalReducer,
        game1: game1Reducer,
        game2: game2Reducer,
    }
})
export type RootState = ReturnType<typeof store.getState>
export default store;

// Handle gameState TUTORIAL COUNTDOWN START INGAME

import React, {Component} from "react";
import {connect, ConnectedProps} from 'react-redux'
import {RootState} from "../../../redux";
import {changeGameState} from "../../../redux/global";
import {GameType, GameState} from "../../../types";
import CountDown from "./CountDown";
import Start from "./Start";
import {GameInstance as GameInstance1, Tutorial as Tutorial1} from "../game1";
import {GameInstance as GameInstance2, Tutorial as Tutorial2} from "../game2";
import Title from "../../../components/Title";
import '../frame.css';

const GameMap = (game: GameType) => {
    switch (game) {
        case GameType.MEMORY_GAME:
            return {Tutorial: Tutorial1, GameInstance: GameInstance1};
        case GameType.NUMBER_GAME:
            return {Tutorial: Tutorial2, GameInstance: GameInstance2};
        case GameType.UNDEFINED:
            throw new Error(`Unknown Game ${GameType.UNDEFINED}`);
    }
}

const reduxConnector = connect(
    (state: RootState, ownProps: Partial<GameFrameProps>) => ({
        gameState: state.global.gameState,
        game: state.global.game
    }),
    {changeGameState},
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        store: stateProps,
        dispatcher: dispatchProps,
    })
)

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type ConnectedGameFrameProps = PropsFromRedux & GameFrameProps;

export interface GameFrameProps {

}

export interface GameFrameState {

}

class GameFrame extends Component<ConnectedGameFrameProps, GameFrameState> {
    readonly name = "GameFrame";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {}
    static defaultProps = {}

    constructor(props: ConnectedGameFrameProps) {
        super(props);
        this.log("constructor", props);
    }

    componentDidMount() {

    }

    tutorial_onDone = () => {
        this.props.dispatcher.changeGameState(GameState.COUNTDOWN);
    }

    countDown_onDone = () => {
        this.props.dispatcher.changeGameState(GameState.START);
    }

    start_onDone = () => {
        this.props.dispatcher.changeGameState(GameState.INGAME);
    }

    inGame_onDone = () => {
        this.props.dispatcher.changeGameState(GameState.ENDGAME);
    }
    inGame_onPause = () => {
        this.props.dispatcher.changeGameState(GameState.PAUSE);
    }

    genInsideFrame() {
        const {Tutorial, GameInstance} = GameMap(this.props.store.game);
        switch (this.props.store.gameState) {
            case GameState.TUTORIAL:
                return <Tutorial onDone={this.tutorial_onDone}/>
            case GameState.COUNTDOWN:
                return <CountDown onDone={this.countDown_onDone}/>
            case GameState.START:
                return <Start onDone={this.start_onDone}/>
            case GameState.INGAME:
                return <GameInstance onDone={this.inGame_onDone} onPause={this.inGame_onPause}/>
            default:
                throw new Error("Unknown GameState for GameFrame");
        }
    }

    render() {
        return (
            <div className={"gameFrame"}>
                {this.genInsideFrame()}
            </div>
        )
    }
}

export default reduxConnector<any>(GameFrame);

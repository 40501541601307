import React, {Component} from "react";
import {connect, ConnectedProps} from 'react-redux';
import {WithTranslation, withTranslation} from "react-i18next";
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';

import Button from "../../../components/Button";
import Title from "../../../components/Title";
import {setGameInstanceState} from "../../../redux/game1";
import {RootState} from "../../../redux";
import {changeGameState} from "../../../redux/global";
import AnimatedBox from "../../../components/AnimatedBox";
import Scoreboard from "../common/Scoreboard";
import {randomInt} from "../../../utils";
import {GameObj} from "../../../types/game1";

const reduxConnector = connect(
    (state: RootState, ownProps: Partial<GameInstanceProps>) => ({
        gameState: state.global.gameState,
        game: state.global.game,
        initialState: state.game1.data,
    }),
    {setGameInstanceState},
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        store: stateProps,
        dispatcher: dispatchProps,
    })
)

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type ConnectedGameInstanceProps = PropsFromRedux & GameInstanceProps;

export interface GameInstanceProps extends WithTranslation {
    onPause: () => any,
    onDone: () => any,
}

export interface GameInstanceState {
    timer: number,
    level: number,
    gameObjs: Array<GameObj>,
    score: number,
    attempts: number,
    allowClick: boolean,
}


const MAX_TIME = 60;
const COLORS = ["#8b4513", "#6b8e23", "#ff4500", "#ffff00", "#0000cd", "#00ff00", "#00fa9a", "#00bfff", "#ff00ff", "#dda0dd", "#ff1493", "#fafad2"]

class GameInstance extends Component<ConnectedGameInstanceProps, GameInstanceState> {
    readonly name = "數字遊戲";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {}
    static defaultProps = {}

    private timerCancel: ReturnType<typeof setInterval> | null;

    private DIM_DEF = {
        CANVAS_VIEW: {x: [0, 100], y: [0, 100]},
        CANVAS_COORD: {x: 0, y: 0},
        BOX_VIEW_DIM: {x: 15, y: 15},
    }
    private canvasRef: HTMLDivElement | null;

    constructor(props: ConnectedGameInstanceProps) {
        super(props);
        this.log("constructor", props);
        this.state = {
            timer: 0,
            level: -1,
            score: 0,
            attempts: 0,
            gameObjs: [],
            allowClick: false,
            ...this.props.store.initialState
        }
        this.timerCancel = null;
        this.canvasRef = null;
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.refreshCanvasDim();
            this.log("resized", this.DIM_DEF.CANVAS_COORD);
        });
        let waitUnitCanvasRef: NodeJS.Timeout = setInterval(() => {
            if (this.canvasRef) {
                let fetchDimSucc = this.refreshCanvasDim();
                if (fetchDimSucc) {
                    clearInterval(waitUnitCanvasRef);
                }
            }
        }, 500);
        this.startTimer();
        if (this.state.level < 0) {
            this.initLevel(0);
        } else {
            this.resumeLevel();
        }
    }

    /* view and coordination */

    private refreshCanvasDim = () => {
        if (this.canvasRef) {
            this.DIM_DEF.CANVAS_COORD = {
                x: this.canvasRef.clientWidth,
                y: this.canvasRef.clientHeight,
            }
            this.log("refreshCanvasDim", "Canvas", this.DIM_DEF.CANVAS_COORD)
            return true;
        }
        return false;
    }

    private view2Coord(x?: number, y?: number) {
        if (x !== undefined && y !== undefined) {
            return {x: this.view2CoordX(x), y: this.view2CoordY(y)};
        } else if (x !== undefined) {
            return this.view2CoordX(x);
        } else if (y !== undefined) {
            return this.view2CoordY(y);
        } else {
            return null;
        }
    }

    private view2CoordX(x: number) {
        return ((x - this.DIM_DEF.CANVAS_VIEW.x[0]) / (this.DIM_DEF.CANVAS_VIEW.x[1] - this.DIM_DEF.CANVAS_VIEW.x[0])) * this.DIM_DEF.CANVAS_COORD.x;
    }

    private view2CoordY(y: number) {
        return ((y - this.DIM_DEF.CANVAS_VIEW.y[0]) / (this.DIM_DEF.CANVAS_VIEW.y[1] - this.DIM_DEF.CANVAS_VIEW.y[0])) * this.DIM_DEF.CANVAS_COORD.y;
    }

    /* Timer */

    startTimer() {
        if (this.timerCancel === null) {
            this.timerCancel = setInterval(() => {
                this.setState(state => ({timer: state.timer + 1}), () => {
                    if (this.state.timer >= MAX_TIME * 10) {
                        this.stopTimer();
                        this.onTimeout();
                    }
                })
            }, 100)
        }
    }

    stopTimer() {
        this.timerCancel && clearInterval(this.timerCancel);
        this.timerCancel = null;
    }

    stringifyTimer() {
        let timer: number = MAX_TIME * 10 - this.state.timer;
        return `${Math.floor(timer / 10)}.${Math.abs(Math.floor((timer % 10)))}`;
    }

    /* Nav */

    toPause() {
        this.stopTimer();
        this.props.dispatcher.setGameInstanceState(this.state);
        this.props.onPause?.();
    }

    toDone() {
        this.stopTimer();
        this.props.dispatcher.setGameInstanceState(this.state);
        this.props.onDone?.();
    }

    /* Game logic */

    private genBoxLoc(nBoxes: number) {
        let boxes: Array<Array<number>> = [];
        while (boxes.length < nBoxes) {
            let x = randomInt(this.DIM_DEF.BOX_VIEW_DIM.x * 0.5, this.DIM_DEF.CANVAS_VIEW.x[1] - this.DIM_DEF.BOX_VIEW_DIM.x * 1.5);
            let y = randomInt(this.DIM_DEF.BOX_VIEW_DIM.y * 0.5, this.DIM_DEF.CANVAS_VIEW.y[1] - this.DIM_DEF.BOX_VIEW_DIM.y * 1.5);
            let z = randomInt(1, 99);
            let ok = true;
            for (let box of boxes) {
                if (Math.abs(x - box[0]) <= this.DIM_DEF.BOX_VIEW_DIM.x && Math.abs(y - box[1]) <= this.DIM_DEF.BOX_VIEW_DIM.y) {
                    ok = false;
                    break;
                }
            }
            if (ok) {
                do {
                    z = randomInt(1, 99);
                    for (let box of boxes) {
                        if (Math.abs(x - box[0]) <= this.DIM_DEF.BOX_VIEW_DIM.x && Math.abs(y - box[1]) <= this.DIM_DEF.BOX_VIEW_DIM.y) {
                            ok = false;
                            break;
                        }
                    }
                } while (!ok);
                boxes.push([x, y, z]);
            }
        }
        boxes = boxes.sort((a, b) => {
            if (a[2] > b[2]) {
                return 1
            } else if (a[2] === b[2]) {
                return 0
            } else return -1
        })
        return boxes;
    }

    private initLevel(level: number) {
        let nBoxes = Math.min(9, Math.max(3, Math.floor(level / 5) + 3));
        let boxesCoord = this.genBoxLoc(nBoxes);
        this.log("initLevel", "boxCoord", boxesCoord);
        let boxes: Array<GameObj> = boxesCoord.map((box, i) => ({
            x: box[0],
            y: box[1],
            seq: i,
            show: false,
            clicked: false,
            text: box[2].toString(10),
            backgroundColor: COLORS[Math.floor(Math.random() * COLORS.length)],
        }))
        this.setState({level, gameObjs: boxes, allowClick: false}, () => {
            setTimeout(() => {
                this.showBoxes(Array(boxes.length).fill(0).map((x, i) => i));
                this.setState({allowClick: true});
            }, 500);
        });
    }

    private resumeLevel() {
        // this.log("resumeLevel", this.state.gameObjs);
        this.hideBoxes(Array(this.state.gameObjs.length).fill(0).map((x, i) => i));
        this.setState({allowClick: false}, () => {
            let count = 0;
            this.showBoxes(Array(this.state.gameObjs.length).fill(0).map((x, i) => i));
            this.setState({allowClick: true});
            let showTimer = setInterval(() => {
                if (this.state.gameObjs[count].clicked) {
                    const index = count;
                    this.hideBox(index);
                } else {
                    this.showBox(count);
                }
                count++;
                if (count >= this.state.gameObjs.length) {
                    this.setState({allowClick: true});
                    clearInterval(showTimer);
                }
            }, 500)
        });
    }

    private showBox(index: number, clicked: boolean | null = null, cb?: () => void) {
        if (index < this.state.gameObjs.length) {
            this.setState((state) => ({
                gameObjs: [...state.gameObjs.slice(undefined, index), {
                    ...state.gameObjs[index],
                    show: true,
                    clicked: clicked !== null ? clicked : state.gameObjs[index].clicked,
                }, ...state.gameObjs.slice(index + 1, undefined)]
            }), cb);
        }
    }

    private hideBox(index: number, clicked: boolean | null = null, cb?: () => void) {
        this.setState((state) => ({
            gameObjs: [...state.gameObjs.slice(undefined, index), {
                ...state.gameObjs[index],
                show: false,
                clicked: clicked !== null ? clicked : state.gameObjs[index].clicked,
            }, ...state.gameObjs.slice(index + 1, undefined)]
        }), cb);
    }

    private toggleBox(index: number, clicked: boolean | null = null, cb?: () => void) {
        this.setState((state) => ({
            gameObjs: [...state.gameObjs.slice(undefined, index), {
                ...state.gameObjs[index],
                show: !state.gameObjs[index].show,
                clicked: clicked !== null ? clicked : state.gameObjs[index].clicked,
            }, ...state.gameObjs.slice(index + 1, undefined)]
        }), cb);
    }

    private showBoxes(indexs: Array<number>, clicked: boolean | null = null, cb?: () => void) {
        let newBoxes = [...this.state.gameObjs];
        for (let i of indexs) {
            if (i < newBoxes.length) {
                newBoxes[i] = {...newBoxes[i], show: true};
                clicked = clicked !== null ? clicked : newBoxes[i].clicked;
            }
        }
        this.setState({gameObjs: newBoxes}, cb);
    }

    private hideBoxes(indexs: Array<number>, clicked: boolean | null = null, cb?: () => void) {
        let newBoxes = [...this.state.gameObjs];
        for (let i of indexs) {
            if (i < newBoxes.length) {
                newBoxes[i] = {...newBoxes[i], show: false};
                clicked = clicked !== null ? clicked : newBoxes[i].clicked;
            }
        }
        this.setState({gameObjs: newBoxes}, cb);
    }

    private toggleBoxes(indexs: Array<number>, clicked: boolean | null = null, cb?: () => void) {
        let newBoxes = [...this.state.gameObjs];
        for (let i of indexs) {
            if (i < newBoxes.length) {
                newBoxes[i] = {...newBoxes[i], show: !newBoxes[i].show};
                clicked = clicked !== null ? clicked : newBoxes[i].clicked;
            }
        }
        this.setState({gameObjs: newBoxes}, cb);
    }

    private addBox(gameObj: GameObj, show: boolean | null) {
        if (show !== null) {
            gameObj.show = show;
        }
        this.setState((state) => ({
            gameObjs: [...state.gameObjs, gameObj]
        }))
    }

    private removeBox(index: number) {
        let newBoxes = [...this.state.gameObjs];
        newBoxes = newBoxes.splice(index, 1);
        this.setState({gameObjs: newBoxes});
    }

    private addBoxes(boxes: Array<GameObj>, show: boolean | null) {
        if (show !== null) {
            for (let i = 0; i < boxes.length; i++) {
                boxes[i].show = show;
            }
        }
        this.setState((state) => ({
            gameObjs: [...state.gameObjs, ...boxes]
        }))
    }

    private removeBoxes(indexs: Array<number>) {
        let newBoxes = [...this.state.gameObjs];
        indexs = indexs.sort().reverse();
        for (let index of indexs) {
            newBoxes = newBoxes.splice(index, 1);
        }
        this.setState({gameObjs: newBoxes});
    }

    private checkWinCondition() {
        let allClicked = this.state.gameObjs.map((gameObj) => (gameObj.clicked)).reduce((a, b) => (a && b), true);
        if (allClicked) {
            this.setState((state) => ({score: state.score + state.gameObjs.length * 5, gameObjs: []}), () => {
                this.initLevel(this.state.level + 1);
            });
        }
    }

    private clickCanvas() {
        this.setState((state) => ({
            attempts: state.attempts + 1,
        }));
    }

    private onTimeout() {
        // this.toDone();
    }

    /* UI handler */

    private clickBox(index: number) {
        this.log("clickBox", "box onClicked", index, "allowClick", this.state.allowClick);
        let allow = this.state.allowClick;
        if (allow) {
            for (let i = 0; i < index; i++) {
                if (!this.state.gameObjs[i].clicked) {
                    allow = false;
                    break;
                }
            }
        }
        if (allow) {
            this.hideBox(index, true, () => {
                this.checkWinCondition();
            });
        }
    }

    render() {
        return (
            <>
                <div className={"header"}>
                    <div style={{alignSelf: 'flex-start', marginLeft: '5%', display: 'flex', flexDirection: 'row'}}>
                        <Title/>
                        <Button onClick={this.toPause.bind(this)} style={{marginTop: 0, marginBottom: 0}}>
                            <PauseIcon style={{}}/>
                        </Button>
                        <Button onClick={this.toDone.bind(this)} style={{marginTop: 0, marginBottom: 0}}>
                            <StopIcon style={{}}/>
                        </Button>
                    </div>
                </div>
                <div className={"frame"}>
                    <div className={"canvas"} ref={(ref) => {
                        this.canvasRef = ref
                    }} onClick={() => {
                        this.clickCanvas()
                    }}>
                        {this.DIM_DEF.CANVAS_COORD.x !== 0 && this.DIM_DEF.CANVAS_COORD.y !== 0 && (
                            this.state.gameObjs.map((gameObj, i) => {
                                return <AnimatedBox key={i} show={gameObj.show}>
                                    <div className={"game1box"}
                                         style={{
                                             display: gameObj.show || gameObj.clicked ? "flex" : "none",
                                             width: this.view2CoordX(this.DIM_DEF.BOX_VIEW_DIM.x),
                                             height: this.view2CoordX(this.DIM_DEF.BOX_VIEW_DIM.y),
                                             left: this.view2CoordX(gameObj.x),
                                             top: this.view2CoordY(gameObj.y),
                                             borderRadius: this.view2CoordX(this.DIM_DEF.BOX_VIEW_DIM.x) * 0.3,
                                             backgroundColor: gameObj.backgroundColor,
                                         }}
                                         onClick={() => {
                                             this.clickBox(i)
                                         }}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: "center",
                                            flex: 1,
                                            fontSize: "2em"
                                        }}>
                                            {gameObj.text}
                                        </div>
                                    </div>
                                </AnimatedBox>
                            })
                        )}
                    </div>
                </div>
                <div className={"footer"}>
                    <Scoreboard score_label={this.props.t("scoreboard.score_label")}
                                time_label={this.props.t("scoreboard.time_label")}
                                level_label={this.props.t("scoreboard.level_label")}
                                score={this.props.t("scoreboard.score_value", {score: this.state.score})}
                                time={this.props.t("scoreboard.time_value", {time: this.stringifyTimer()})}
                                level={this.props.t("scoreboard.level_value", {level: this.state.level + 1})}/>
                </div>
            </>
        )
    }
}

export default reduxConnector<any>(withTranslation()(GameInstance));

import {GameState} from "../types";

export function allowChangeState(curState: GameState, newState: GameState): boolean{
    let allow: boolean = false;
    switch (curState) {
        case GameState.MENU:
            allow = newState === GameState.TUTORIAL || newState === GameState.EXIT;
            break;
        case GameState.TUTORIAL:
            allow = newState === GameState.COUNTDOWN;
            break;
        case GameState.COUNTDOWN:
            allow = newState === GameState.START;
            break;
        case GameState.START:
            allow = newState === GameState.INGAME;
            break;
        case GameState.INGAME:
            allow = newState === GameState.PAUSE || newState === GameState.ENDGAME;
            break;
        case GameState.PAUSE:
            allow = newState === GameState.INGAME || newState === GameState.COUNTDOWN || newState === GameState.ENDGAME;
            break;
        case GameState.ENDGAME:
            allow = newState === GameState.COUNTDOWN || newState === GameState.MENU;
            break;
        case GameState.EXIT:
            allow = false;
            break;
    }
    console.log("allowChangeState", curState, newState, allow);
    return allow;
}

import React from 'react';
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../redux";
import {initGame} from "../redux/global";
import {GameType, GameState} from "../types"
import {Menu, CountDown, Start, Pause, EndGame, GameFrame} from "../containers/flow/common"
const reduxConnector = connect(
    (state: RootState, ownProps: GameRouteProps) => ({
        game: state.global.game,
        gameState: state.global.gameState,
    }),
    {initGame},
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        store: stateProps,
        dispatcher: dispatchProps,
    })
)

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type ConnectedGameRouteProps = PropsFromRedux & GameRouteProps;

interface GameRouteProps {
    variant: GameType
}

interface GameRouteState {

}

class GameRoute extends React.Component <ConnectedGameRouteProps, GameRouteState> {
    readonly name = "GameRoute";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {}
    static defaultProps = {}

    constructor(props: ConnectedGameRouteProps) {
        super(props);
        this.log("constructor", props);
        this.state = {

        }
    }

    componentDidMount() {
        this.props.dispatcher.initGame(this.props.variant);
        window.addEventListener("resize", () => {

        })
    }

    componentWillUnmount() {

    }

    genFrame(gameState: GameState) {
        switch (gameState){
            case GameState.MENU:
                return <Menu />;
            case GameState.TUTORIAL:
            case GameState.COUNTDOWN:
            case GameState.INGAME:
            case GameState.START:
                return <GameFrame />;
            case GameState.PAUSE:
                return <Pause />;
            case GameState.ENDGAME:
                return <EndGame />
        }
    }
    render() {
        return (
            <div className="game" style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                {this.genFrame(this.props.store.gameState)}
            </div>
        );
    }
}

export default reduxConnector<any>(GameRoute);

import React, {Component} from "react";
import MButton, {ButtonProps as MButtonProps} from '@mui/material/Button';

export interface ButtonProps extends MButtonProps {

}

export interface ButtonState {

}

const TEXT_COLOR = '#0e4d3b';
const BACKGROUND_COLOR = '#c7e473';
const BORDER_COLOR = '#0e4d3b';

export default class Button extends Component<ButtonProps, ButtonState> {
    constructor(props: ButtonProps) {
        super(props);
    }

    render() {
        return (
            <MButton {...this.props}
                     style={{
                         backgroundColor: BACKGROUND_COLOR,
                         alignSelf: "center",
                         borderColor: BORDER_COLOR,
                         color: TEXT_COLOR,
                         fontSize: '2.5em',
                         padding: '0.3em',
                         marginTop: '0.3em',
                         marginBottom: '0.3em',
                         borderRadius: '0.5em',
                         display: "flex",
                         width: '80%',
                         ...(this.props.style || {})
                     }}
                     variant="outlined"
            />
        );
    }
}

import React, {Component} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import {GameObj} from "../../../types/game1";
import AnimatedBox from "../../../components/AnimatedBox";
import TouchAppIcon from '@mui/icons-material/TouchApp';

export interface TutorialProp extends WithTranslation{
    onDone?: ()=>void,
}

export interface TutorialState {
    gameObjs: Array<GameObj>,
    pointerObjs: Array<PointerObj>,
    reboot: boolean,
}

const COLORS = ["#8b4513", "#6b8e23", "#ff4500", "#ffff00", "#0000cd", "#00ff00", "#00fa9a", "#00bfff", "#ff00ff", "#dda0dd", "#ff1493", "#fafad2"]
interface PointerObj {
    x: number, y:number, show: boolean
}
class Tutorial extends Component<TutorialProp, TutorialState> {
    readonly name = "Tutorial";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {}
    static defaultProps = {}

    private timerCancel: ReturnType<typeof setInterval> | null;

    private DIM_DEF = {
        CANVAS_VIEW: {x: [0, 100], y: [0, 100]},
        CANVAS_COORD: {x: 0, y: 0},
        BOX_VIEW_DIM: {x: 15, y: 15},
    }
    private canvasRef: HTMLDivElement | null;
    private readonly gameObjs: Array<GameObj>;
    private readonly pointerObjs: Array<PointerObj>;

    constructor(props:TutorialProp) {
        super(props);
        this.gameObjs = [
            {
                x: 30, y: 40, clicked: false, backgroundColor: COLORS[0], show: false, seq: 1, text: "8"
            },
            {
                x: 60, y: 30, clicked: false, backgroundColor: COLORS[1], show: false, seq: 2, text: "46"
            },
            {
                x: 10, y: 10, clicked: false, backgroundColor: COLORS[2], show: false, seq: 3, text: "81"
            },
        ];
        this.pointerObjs = this.gameObjs.map((gameObj)=>({
            x: gameObj.x + 5,
            y: gameObj.y + 5,
            show: false,
        }))
        this.state = {
            gameObjs: [],
            pointerObjs: [],
            reboot: false,
        };
        this.timerCancel = null;
        this.canvasRef = null;
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.refreshCanvasDim();
            this.log("resized", this.DIM_DEF.CANVAS_COORD);
        });
        let waitUnitCanvasRef: NodeJS.Timeout = setInterval(() => {
            if (this.canvasRef) {
                let fetchDimSucc = this.refreshCanvasDim();
                if (fetchDimSucc) {
                    clearInterval(waitUnitCanvasRef);
                }
            }
        }, 500);
        this.startTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    /* view and coordination */

    private refreshCanvasDim = () => {
        if (this.canvasRef) {
            this.DIM_DEF.CANVAS_COORD = {
                x: this.canvasRef.clientWidth,
                y: this.canvasRef.clientHeight,
            }
            this.log("refreshCanvasDim", "Canvas", this.DIM_DEF.CANVAS_COORD)
            return true;
        }
        return false;
    }

    private view2Coord(x?: number, y?: number) {
        if (x !== undefined && y !== undefined) {
            return {x: this.view2CoordX(x), y: this.view2CoordY(y)};
        } else if (x !== undefined) {
            return this.view2CoordX(x);
        } else if (y !== undefined) {
            return this.view2CoordY(y);
        } else {
            return null;
        }
    }

    private view2CoordX(x: number) {
        return ((x - this.DIM_DEF.CANVAS_VIEW.x[0]) / (this.DIM_DEF.CANVAS_VIEW.x[1] - this.DIM_DEF.CANVAS_VIEW.x[0])) * this.DIM_DEF.CANVAS_COORD.x;
    }

    private view2CoordY(y: number) {
        return ((y - this.DIM_DEF.CANVAS_VIEW.y[0]) / (this.DIM_DEF.CANVAS_VIEW.y[1] - this.DIM_DEF.CANVAS_VIEW.y[0])) * this.DIM_DEF.CANVAS_COORD.y;
    }

    /* Timer */

    startTimer() {
        if (this.timerCancel === null) {
            let fn = (() => {
                this.addBoxes(() => {
                    let count = 0;
                    let showTimer = setTimeout(() => {
                        this.showBoxes(Array(this.state.gameObjs.length).fill(0).map((x, i)=>i));
                        clearTimeout(showTimer);
                    }, 500)
                });
                setTimeout(()=>{
                    this.addPointers(() => {
                        let count = 0;
                        let showTimer = setInterval(() => {
                            if (count > 0) {
                                this.hidePointer(count-1);
                                this.hideBox(count-1, true)
                            }
                            this.showPointer(count);
                            count++;
                            if (count > 3) {
                                clearInterval(showTimer);
                            }
                        }, 500)
                    });
                }, 500)
            }).bind(this);
            fn();
            this.timerCancel = setInterval(
                ()=>{
                    fn();
                }, 4000)
        }
    }

    stopTimer() {
        this.timerCancel && clearInterval(this.timerCancel);
        this.timerCancel = null;
    }

    /* GameLogic */
    private addBoxes(cb?: ()=>void) {
        this.setState((state) => ({
            gameObjs: [...this.gameObjs],
        }), cb)
    }

    private showBoxes(indexs: Array<number>, clicked: boolean | null = null, cb?: () => void) {
        let newBoxes = [...this.state.gameObjs];
        for (let i of indexs) {
            if (i < newBoxes.length) {
                newBoxes[i] = {...newBoxes[i], show: true};
                clicked = clicked !== null ? clicked : newBoxes[i].clicked;
            }
        }
        this.setState({gameObjs: newBoxes}, cb);
    }

    private hideBox(index: number, clicked: boolean | null = null, cb?: ()=>void) {
        this.setState((state) => ({
            gameObjs: [...state.gameObjs.slice(undefined, index), {
                ...state.gameObjs[index],
                show: false,
                clicked: clicked !== null ? clicked : state.gameObjs[index].clicked,
            }, ...state.gameObjs.slice(index + 1, undefined)]
        }), cb);
    }

    private addPointers(cb?: ()=>void) {
        this.setState((state) => ({
            pointerObjs: [...this.pointerObjs],
        }), cb)
    }

    private showPointer(index: number, cb?: ()=>void) {
        if (index < this.state.pointerObjs.length) {
            this.setState((state) => ({
                pointerObjs: [...state.pointerObjs.slice(undefined, index), {
                    ...state.pointerObjs[index],
                    show: true,
                }, ...state.pointerObjs.slice(index + 1, undefined)]
            }), cb);
        }
    }

    private hidePointer(index: number, cb?: ()=>void) {
        this.setState((state) => ({
            pointerObjs: [...state.pointerObjs.slice(undefined, index), {
                ...state.pointerObjs[index],
                show: false,
            }, ...state.pointerObjs.slice(index + 1, undefined)]
        }), cb);
    }

    /* navigation */

    next(){
        this.props.onDone?.();
    }

    render(){
        return (
            <>
                <div className={"header"}>
                    <Title header={this.props.t("games.1.tutorial.title")}/>
                </div>
                <div className={"frame"}>
                    <div className={"canvas"} ref={(ref) => {
                        this.canvasRef = ref
                    }}>
                        { this.DIM_DEF.CANVAS_COORD.x !== 0 && this.DIM_DEF.CANVAS_COORD.y !== 0 && (
                            this.state.gameObjs.map((gameObj, i)=>{
                                return <AnimatedBox key={i} show={gameObj.show}>
                                    <div className={"game1box"}
                                         style={{
                                             display: gameObj.show || gameObj.clicked ? "flex" : "none",
                                             width: this.view2CoordX(this.DIM_DEF.BOX_VIEW_DIM.x),
                                             height: this.view2CoordX(this.DIM_DEF.BOX_VIEW_DIM.y),
                                             left: this.view2CoordX(gameObj.x),
                                             top: this.view2CoordY(gameObj.y),
                                             borderRadius: this.view2CoordX(this.DIM_DEF.BOX_VIEW_DIM.x) * 0.3,
                                             backgroundColor: gameObj.backgroundColor,
                                         }}
                                    >
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: "center",
                                            flex: 1,
                                            fontSize: "2em"
                                        }}>
                                            {gameObj.text}
                                        </div>
                                    </div>
                                </AnimatedBox>
                            })
                        )}
                        { this.DIM_DEF.CANVAS_COORD.x !== 0 && this.DIM_DEF.CANVAS_COORD.y !== 0 && (
                            this.state.pointerObjs.map((pointerObj, i)=>{
                                return <AnimatedBox key={i} show={pointerObj.show}>
                                    <TouchAppIcon
                                         style={{
                                             position: 'absolute',
                                             display: pointerObj.show ? "block" : "none",
                                             width: this.view2CoordX(this.DIM_DEF.BOX_VIEW_DIM.x),
                                             height: this.view2CoordX(this.DIM_DEF.BOX_VIEW_DIM.y),
                                             left: this.view2CoordX(pointerObj.x),
                                             top: this.view2CoordY(pointerObj.y),
                                             color: '#000000',
                                         }}
                                    />
                                </AnimatedBox>
                            })
                        )}
                        <div style={{backgroundColor: 'grey', display: 'flex', flex: 1, opacity: 0.8, alignSelf: 'flex-end', margin: 5, padding: '0 2em', borderRadius: 20, color: 'black'}}>
                            <p>{this.props.t("games.1.tutorial.howToPlay")}</p>
                        </div>
                    </div>
                </div>
                <div className={"footer"}>
                    <Button onClick={()=>{this.next()}} >{this.props.t('games.1.tutorial.startGame')}</Button>
                </div>
            </>
        );
    }
}
export default withTranslation()(Tutorial)

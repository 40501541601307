import React, {Component} from "react";
import PropTypes from "prop-types";
import {withTranslation, WithTranslation} from "react-i18next";
import Title from "../../../components/Title";
import '../frame.css';
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../../redux";
import {changeGameState} from "../../../redux/global";
import {GameState} from "../../../types";
import Button from "../../../components/Button";
import Scoreboard from "./Scoreboard";

const reduxConnector = connect(
    (state: RootState, ownProps: Partial<CountDownProps>) => ({
        gameState: state.global.gameState,
        game: state.global.game
    }),
    {changeGameState},
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        store: stateProps,
        dispatcher: dispatchProps,
    })
)

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type ConnectedCountDownProps = PropsFromRedux & CountDownProps;

export interface CountDownProps extends WithTranslation {
    onDone?: () => void,
    time: number,
}

export interface CountDownState {
    countdown: number,
}

const MAX_COUNTDOWN = 3;

class CountDown extends Component<ConnectedCountDownProps, CountDownState> {
    readonly name = "CountDown";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {
        onDone: PropTypes.func,
        time: PropTypes.number,
    }

    static defaultProps = {
        onDone: null,
        time: MAX_COUNTDOWN,
    }

    private cancelCb: ReturnType<typeof setInterval> | null = null;

    constructor(props: ConnectedCountDownProps) {
        super(props);
        this.log("constructor", props);
        this.state = {
            countdown: this.props.time,
        }
    }

    defaultOnDone = () => {
        this.props.dispatcher.changeGameState(GameState.START)
    }

    onDone = () => {
        (this.props.onDone || this.defaultOnDone)?.();
    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    startTimer() {
        this.setState({countdown: this.props.time});
        this.cancelCb = setInterval(() => {
            this.setState(state => ({countdown: Math.max(0, state.countdown - 1)}), () => {
                if (this.state.countdown <= 0) {
                    this.stopTimer();
                    this.onDone();
                }
            });
        }, 1000);
    }

    stopTimer() {
        this.cancelCb && clearInterval(this.cancelCb);
    }

    render() {
        return (
            <>
                <div className={"header"}>
                    <Title header={this.props.t("countdown.title")}/>
                </div>
                <div className={"frame"}>
                    <div className={"canvas"}>
                        <Title header={(this.state.countdown).toString(10)} color={'#0e4d3b'}/>
                    </div>
                </div>
                <div className={"footer"}>
                    <Scoreboard score_label={this.props.t("scoreboard.score_label")}
                                time_label={this.props.t("scoreboard.time_label")}
                                level_label={this.props.t("scoreboard.level_label")}
                                score={this.props.t("scoreboard.score_value", {score: 0})}
                                time={this.props.t("scoreboard.time_value", {time: 0})}
                                level={this.props.t("scoreboard.level_value", {level: 1})} />
                </div>
            </>
        );
    }
}

export default reduxConnector<any>(withTranslation()(CountDown));

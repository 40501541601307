import React, {Component, StyleHTMLAttributes} from "react";
import PropTypes from "prop-types";

export interface TitleProps {
    header: string,
    subheader?: string,
    color: string,
}
export interface TitleState {

}
export default class Title extends Component<TitleProps, TitleState> {
    readonly name = "CountDown";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {
        header: PropTypes.string,
        subheader: PropTypes.string,
        color: PropTypes.string,
    }

    static defaultProps = {
        header: '\u00A0',
        color: "#ffffff",
    }

    constructor(props: TitleProps) {
        super(props);
    }
    render(){
        return (
            <div className={"Title"} style={{display: 'flex', alignSelf: 'center', justifyContent: 'center',
                color: this.props.color, fontSize: '3.5em', width: '100%', overflow: 'wrap'}}>
                {this.props.header}
                {this.props.subheader && <span>{this.props.subheader}</span>}
            </div>
        )
    }
}

import React, {Component} from "react";
import PropTypes from "prop-types";
import Title from "../../../components/Title";
import '../frame.css';
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../../redux";
import {changeGameState} from "../../../redux/global";
import {GameState} from "../../../types";

export interface ScoreboardProps {
    score_label: string,
    time_label: string,
    level_label: string,
    score: string,
    time: string,
    level: string
}

export interface ScoreboardState {
}

const MAX_Scoreboard = 0;

class Scoreboard extends Component<ScoreboardProps, ScoreboardState> {
    readonly name = "Scoreboard";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {
        score_label: PropTypes.string.isRequired,
        time_label: PropTypes.string.isRequired,
        level_label: PropTypes.string.isRequired,
        score: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
    }

    static defaultProps = {}

    constructor(props: ScoreboardProps) {
        super(props);
        this.log("constructor", props);
        this.state = {}
    }

    render() {
        return (
            <div style={{backgroundColor: 'white', display: "flex", flexDirection: 'row', width: "90%", borderRadius: 20}}>
                <table cellSpacing="0" cellPadding="0" style={{border: "none", tableLayout: "auto", width: '100%', margin: '3% 8%', fontSize: '2em'}}>
                    <colgroup>
                        <col style={{width: "2%"}}/>
                        <col style={{width: "2%"}}/>
                        <col style={{whiteSpace: "nowrap"}}/>

                    </colgroup>
                    <tr>
                        <th style={{textAlign: "right", whiteSpace: "nowrap"}}>{this.props.score_label}</th>
                        <td style={{paddingLeft: "2%", paddingRight: "2%"}}>:</td>
                        <td style={{textAlign: "center"}}>{this.props.score}</td>
                    </tr>
                    <tr>
                        <th style={{textAlign: "right", whiteSpace: "nowrap"}}>{this.props.time_label}</th>
                        <td style={{paddingLeft: "2%", paddingRight: "2%"}}>:</td>
                        <td style={{textAlign: "center"}}>{this.props.time}</td>
                    </tr>
                    <tr>
                        <th style={{textAlign: "right", whiteSpace: "nowrap"}}>{this.props.level_label}</th>
                        <td style={{paddingLeft: "2%", paddingRight: "2%"}}>:</td>
                        <td style={{textAlign: "center"}}>{this.props.level}</td>
                    </tr>
                </table>
            </div>
        );
    }
}

export default Scoreboard;

import React, {Component} from "react";
import {connect, ConnectedProps} from 'react-redux'
import {WithTranslation, withTranslation} from "react-i18next";
import {RootState} from "../../../redux";
import {changeGameState, initGame} from "../../../redux/global";
import Title from "../../../components/Title";
import Button from "../../../components/Button";
import {GameState} from "../../../types";
import '../frame.css';

const reduxConnector = connect(
    (state: RootState, ownProps: Partial<PauseProps>) => ({
        gameState: state.global.gameState,
        game: state.global.game
    }),
    {initGame, changeGameState},
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        store: stateProps,
        dispatcher: dispatchProps,
    })
)

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type ConnectedPauseProps = PropsFromRedux & PauseProps;

export interface PauseProps extends WithTranslation {

}

export interface PauseState {

}

class Pause extends Component<ConnectedPauseProps, PauseState> {
    readonly name = "Pause";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {}
    static defaultProps = {}

    constructor(props: ConnectedPauseProps) {
        super(props);
        this.log("constructor", props);
    }

    componentDidMount() {

    }

    resume() {
        this.props.dispatcher.changeGameState(GameState.INGAME);
    }

    restart() {
        this.props.dispatcher.changeGameState(GameState.COUNTDOWN);
    }

    endgame() {
        this.props.dispatcher.changeGameState(GameState.ENDGAME);
    }

    render() {
        return (
            <>
                <div className={"header"}>
                    <Title header={this.props.t("pause.title")}/>
                </div>
                <div className={"frame"}>
                        <Button onClick={this.resume.bind(this)}>{this.props.t("pause.resume")}</Button>
                        <Button onClick={this.restart.bind(this)}>{this.props.t("pause.restart")}</Button>
                        <Button onClick={this.endgame.bind(this)}>{this.props.t("pause.endGame")}</Button>
                </div>
            </>
        )
    }
}

export default reduxConnector<any>(withTranslation()(Pause));

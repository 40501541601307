import {createSlice} from '@reduxjs/toolkit';
import {GameResult, GameState, GameType} from "../types";
import {allowChangeState} from "../utils/rules";


export interface GlobalState {
    game: GameType
    gameState: GameState
    gameResults: Array<GameResult>
}

export const globalSlice = createSlice({
    name: 'global',
    initialState: {
        gameState: GameState.MENU,
        game: GameType.UNDEFINED,
        gameResults: [],
    } as GlobalState,
    reducers: {
        changeGameState: (state, {payload}) => {
            if (allowChangeState(state.gameState, payload)) {
                state.gameState = payload;
            } else {
                console.error(`State transition not allowed: ${state.gameState}->${payload}`);
            }
        },
        initGame: (state, {payload}) => {
            if (allowChangeState(state.gameState, GameState.TUTORIAL)) {
                state.game = payload;
                state.gameState = GameState.TUTORIAL;
            } else {
                console.error(`State transition not allowed: ${state.gameState}->${GameState.TUTORIAL}`);
            }
        },
        initGameForce: (state, {payload}) => {
            state.game = payload;
            state.gameState = GameState.TUTORIAL;
        },
        addGameResults:(state, {payload}) => {
            state.gameResults.push(payload);
        },
    }
});

export default  globalSlice.reducer;
export const {changeGameState, initGame, initGameForce, addGameResults} = globalSlice.actions

import React from 'react';
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../redux";
import {Link, Navigate} from "react-router-dom";
import {changeGameState, initGameForce} from "../redux/global";
import Title from "../components/Title";
import Button from "../components/Button";
import {WithTranslation, withTranslation} from "react-i18next";
import {GameState, GameType} from "../types";

const reduxConnector = connect(
    (state: RootState) => ({
        game: state.global.game
    }),
    {changeGameState, initGameForce},
    (stateProps, dispatchProps, ownProps) => ({
        ...ownProps,
        store: stateProps,
        dispatcher: dispatchProps,
    })
)

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;

type ConnectedMainRouteProps = PropsFromRedux & MainRouteProps;

interface MainRouteProps extends WithTranslation{

}

interface MainRouteState {
    _charts_div_dim: { width: number, height: number },
    _detectionCards_div_dim: { width: number, height: number },
    to: string,
}

class MainRoute extends React.Component <ConnectedMainRouteProps, MainRouteState> {
    readonly name = "Main";
    readonly NAME = this.constructor.name;

    private log = (tb: string, ...args: any[]) => {
        console.log(`[${this.name}[${this.NAME}]-${tb}]`, ...args)
    }

    static PropsType = {}
    static defaultProps = {}

    constructor(props: ConnectedMainRouteProps) {
        super(props);
        this.log("constructor", props);
        this.state = {
            _charts_div_dim: {width: 0, height: 0},
            _detectionCards_div_dim: {width: 0, height: 0},
            to: "",
        }
    }

    componentDidMount() {
        this.log("componentDidMount")
        this.props.dispatcher.initGameForce(GameType.UNDEFINED);
    }

    componentWillUnmount() {
    }

    gotoGame1() {
        this.props.dispatcher.initGameForce(GameType.MEMORY_GAME);
        setTimeout(()=>{
            this.setState({to: '/game1'})
        }, 200)
    }
    gotoGame2() {
        this.props.dispatcher.initGameForce(GameType.NUMBER_GAME);
        setTimeout(()=>{
            this.setState({to: '/game2'})
        }, 200)
    }

    exit() {
        this.props.dispatcher.initGameForce(GameType.UNDEFINED);
	try {
           // @ts-ignore
           window.JSInterface?.close();
	} catch(e: any) {
        }
        setTimeout(()=>{
            this.setState({to: '/exitwebview'});
	    window.location.href = "./exitwebview";
        }, 200)
    }

    render() {
        return (
            <div className="App" style={{display: 'flex', width: '100%', height: '100%'}}>
                <div className={"header"}>
                    <Title header={this.props.t("menu.title")}/>
                </div>
                <div className={"frame"}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: '70%'
                    }}>
                        <Button onClick={this.gotoGame1.bind(this)}>{this.props.t("games.0.name")}</Button>
                        <Button onClick={this.gotoGame2.bind(this)}>{this.props.t("games.1.name")}</Button>
                        <Button onClick={this.exit.bind(this)}>{this.props.t("menu.exit")}</Button>
                    </div>
                </div>
                <div className={"footer"}>
                </div>
                {this.state.to && <Navigate to={this.state.to} />}
            </div>
        );
    }
}

export default reduxConnector<any>(withTranslation()(MainRoute));
